import React from 'react';
import Navbar from '../../Navbar';
import './style.scss';
import CTAButtons from '../../CTAButtons';
import Slide from 'react-reveal/Slide';

const Header = () => {
    return (
        <div className='engageWithHeaderWrap'>
            <div className='engageContainer'>
                <Navbar />

                <div className='ewHeaderContentWrap'>
                    <Slide top>
                        <div className='ewHeaderContentLeft'>
                            <h1>
                                How well do you
                                <br />
                                know your team?
                            </h1>
                            <p>
                                Your employees want their opinion heard. So we help you get a pulse of it right inside
                                Slack and MS Teams.
                            </p>
                            <CTAButtons />
                        </div>
                    </Slide>
                    <Slide right>
                        <div className='ewHeaderContentRight'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/ps-illustration.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/ps-illustration.svg'}
                                alt=''
                            />
                        </div>
                    </Slide>
                </div>
            </div>
        </div>
    );
};

export default Header;
