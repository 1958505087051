import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../../components/engagewith/PulseSurvey/Header';
import AppreciationPercent from '../../../components/engagewith/PulseSurvey/AppreciationPercent';
import WorksWhereHappens from '../../../components/engagewith/PulseSurvey/WorksWhereHappens';
import BuildCulture from '../../../components/engagewith/BuildCulture';
import Footer from '../../../components/engagewith/Footer';
import './styles.scss';

const Lander = () => {
    return (
        <div className='employeePulseSndFeedback'>
            <Helmet>
                <title>Employee Pulse Survey inside Slack & MS Teams | EngageWith</title>
                <meta property='og:title' content='Employee Pulse Survey inside Slack & MS Teams | EngageWith' />
                <meta property='og:site_name' content='EngageWith' />
                <meta property='og:url' content='https://www.springworks.in/engagewith/pulse-survey/' />
                <meta
                    property='og:description'
                    content='Create & send out conversational periodic pulses. Get instant answers to your most important questions inside your channels on Slack & Microsoft Teams.'
                />
                <meta
                    name='description'
                    content='Create & send out conversational periodic pulses. Get instant answers to your most important questions inside your channels on Slack & Microsoft Teams.'
                />
                <meta property='og:type' content='website' />
                <meta
                    property='og:image'
                    content='https://assets-springengage.s3.amazonaws.com/web/engage_header_logo.png'
                />
                <link rel='canonical' href='https://www.springworks.in/engagewith/pulse-survey/' />
                <meta name='twitter:card' content='summary' />
                <meta name='twitter:site' content='@springroleinc' />
                <meta name='twitter:title' content='Employee Pulse Survey inside Slack & MS Teams | EngageWith' />
                <meta
                    name='twitter:description'
                    content='Create & send out conversational periodic pulses. Get instant answers to your most important questions inside your channels on Slack & Microsoft Teams.'
                />
                <meta
                    name='twitter:image'
                    content='https://assets-springengage.s3.amazonaws.com/web/engage_header_logo.png'
                />
                <meta property='og:image:width' content='1200' />
                <meta property='og:image:height' content='600' />
                <script type='application/ld+json'>
                    {`{
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "EngageWith",
                        "url": "https://www.springworks.in/engagewith/",
                        "logo": "https://d1xyh4hv0scrr0.cloudfront.net/web/engage_logo.jpg",
                        "sameAs": [
                          "https://www.facebook.com/springrole",
                          "https://twitter.com/springroleinc/",
                          "https://www.linkedin.com/organization-guest/company/springrole/",
                          "https://www.youtube.com/channel/UC7jYeZWJHhsV_2266gVMsZw"
                        ]
                    }`}
                </script>
            </Helmet>
            <Header />
            <AppreciationPercent />
            <WorksWhereHappens />
            <BuildCulture />
            <Footer />
        </div>
    );
};

export default Lander;
