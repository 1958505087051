import React from 'react';
import './style.scss';
import CTAButtons from '../CTAButtons';

const BuildCulture = ({ pricing }) => {
    return (
        <div className='buildCultureContainter'>
            <div className='buildCultureWrap'>
                <div className='engageContainer'>
                    <div className='buildCultureHeader'>
                        <h2>Build a culture of recognition to…</h2>
                    </div>

                    <div className='buildCulture'>
                        <div className='buildCultureCard'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/talent.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/talent.png'}
                                alt='talent'
                            />
                            <h5>Attract Talent</h5>
                            <p>Build a workplace where A-Players aspire to work</p>
                        </div>
                        <div className='buildCultureCard'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/teams.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/teams.png'}
                                alt='teams'
                            />
                            <h5>Align Teams</h5>
                            <p>Get your entire workforce in the right direction</p>
                        </div>
                        <div className='buildCultureCard'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/engage.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/engage.png'}
                                alt='Engage'
                            />
                            <h5>Drive Engagement</h5>
                            <p>Make your people feel pumped about their job</p>
                        </div>
                        <div className='buildCultureCard'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/spot.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/spot.png'}
                                alt='Spot'
                            />
                            <h5>Spot Recognition</h5>
                            <p>Celebrate moments by recognizing on the spot</p>
                        </div>
                    </div>

                    <div className='buildCulture'>
                        <div className='buildCultureCard'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/productivity.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/productivity.png'}
                                alt='productivity'
                            />
                            <h5>Increase Productivity</h5>
                            <p>Help your people deliver amazing results via timely appreciation</p>
                        </div>
                        <div className='buildCultureCard'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/retention.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/retention.png'}
                                alt='retention'
                            />
                            <h5>Increase Retention</h5>
                            <p>Hold on tight to your best performers</p>
                        </div>
                        <div className='buildCultureCard'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/promoting.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/promoting.png'}
                                alt='promoting'
                            />
                            <h5>Promote Company Values</h5>
                            <p>Make company core values a part of all recognitions</p>
                        </div>
                        <div className='buildCultureCard'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/hand-shake.png'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/promoting.png'}
                                alt='handshake'
                            />
                            <h5>Foster a Committed Workforce</h5>
                            <p>Build a sense of responsibility and dedication</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='buildCultureCTAWrap homePagebuildCultureCTAWrap'>
                <div className='engageContainer'>
                    <div className='buildCulture'>
                        <div className='buildCultureCTA'>
                            <h3 className={pricing ? 'pl-0' : ''}>
                                {!pricing ? `Build a culture that lasts forever.` : ''}
                                <br />
                                Start a 3-month trial.
                            </h3>
                            <CTAButtons />

                            <div className='hPBCCTAImg'>
                                <img
                                    src={'https://sw-assets.gumlet.io/engagewith/pricing/illustration.svg'}
                                    data-src={'https://sw-assets.gumlet.io/engagewith/pricing/illustration.svg'}
                                    alt={'mobImg'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuildCulture;
