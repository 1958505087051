import React from 'react';
import './style.scss';

const WorksWhereHappens = () => {
    return (
        <>
            <div className='engageContainer'>
                <div className='worksWhereHappensWrap'>
                    <div className='worksWhereHappensLeft'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/illustration.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/illustration.svg'}
                            alt=''
                        />
                    </div>
                    <div className='worksWhereHappensRight'>
                        <h2>
                            Works where
                            <br />
                            work happens
                        </h2>
                        <p>
                            EngageWith works where your team does i.e Slack and MS Teams. So get to know your team
                            better without switching apps.{' '}
                        </p>
                    </div>
                </div>
            </div>

            <div className='getTenXMoreWrap appIllustration'>
                <div className='getTenXMoreLeft'>
                    <h4>
                        Get 10x more
                        <br />
                        responses, instantly
                    </h4>
                    <p>
                        Wave good-bye to traditional surveys. EngageWith works seamlessly inside Slack and Teams, which
                        means participation is instant, effortless, and engaging. How engaging? Simply select a channel
                        rather than individuals and replace surveys with a quick, contextual pulse.{' '}
                    </p>
                </div>
                <div className='getTenXMoreRight getIllustration'>
                    <img
                        src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/app-illustration.png'}
                        data-src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/app-illustration.png'}
                        alt=''
                    />
                </div>
            </div>

            <div className='engageContainer'>
                <div className='worksWhereHappensWrap inspireWrap'>
                    <div className='worksWhereHappensLeft'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/inspire.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/inspire.svg'}
                            alt=''
                        />
                    </div>
                    <div className='worksWhereHappensRight'>
                        <h2>
                            Inspire involvement
                            <br />
                            with incognito responses
                        </h2>
                        <p>
                            Want honest answers to critical, but sensitive questions? Make survey responses anonymous
                            and empower transparency.{' '}
                        </p>
                    </div>
                </div>

                <div className='getTenXMoreWrap notSureSection'>
                    <div className='getTenXMoreLeft'>
                        <h4>
                            Not sure where
                            <br />
                            to begin?
                        </h4>
                        <p>EngageWith’s 45+ pre-built templates give you a head-start in knowing your team better. </p>
                    </div>
                    <div className='getTenXMoreRight notSureIllustration'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/not-sure.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/not-sure.svg'}
                            alt=''
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorksWhereHappens;
