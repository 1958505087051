import React from 'react';
import './style.scss';
import { Slide, Zoom } from 'react-reveal';

const AppreciationPercent = () => {
    return (
        <div className='appreciationPercentWrap'>
            <div className='engageContainer'>
                <Slide right>
                    <div className='appreciationPercentHeader'>
                        <h2>
                            An easy way to build a culture of
                            <br />
                            feedback
                        </h2>
                    </div>
                </Slide>
                <div className='appreciationPercentInner'>
                    <Slide left>
                        <div className='appreciationPercent'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/send.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/send.svg'}
                                alt=''
                            />
                            <h3>Send a pulse</h3>
                            <p>
                                Send personalized or group pulse for your team to involve, respond, and let their
                                opinion known.
                            </p>
                        </div>
                    </Slide>
                    <Zoom>
                        <div className='appreciationPercent'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/collect.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/collect.svg'}
                                alt=''
                            />
                            <h3>Collect feedback</h3>
                            <p>Get deeper insights on what they think and identify areas for improvement. </p>
                        </div>
                    </Zoom>

                    <Slide right>
                        <div className='appreciationPercent'>
                            <img src={'https://sw-assets.gumlet.io/engagewith/pulse-survey/make.svg'} alt='' />
                            <h3>Make better decisions</h3>
                            <p>Take action on the feedback provided and build trust with your team. </p>
                        </div>
                    </Slide>
                </div>
            </div>
        </div>
    );
};

export default AppreciationPercent;
